<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Ratios internes" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-text 
            v-model="personnel_entite"
            field-label="Personnel de l'Entité"
            placeholder="Personnel de l'Entité"
            type="number"
            number-integer
            required
            min-max
            class="mt-1"
            @blur="saveFormAnswer"
          />
          <form-fields-radio-group
            v-model="prix_electricite_par_campus_ok"
            field-label="Prix de l'électricité"
            :radio-data-list="preferenceElecList"
            hint="+ ou - 20% aux tarifs connus."
            persistent-hint
            class="mb-6"
            @change="saveFormAnswer"
          />
          <form-fields-text-and-switch 
            v-if="!prix_electricite_par_campus_ok"
            v-model="entite.prix_moyen_electricite_tous_sites"
            :switch-value="entite.prix_moyen_electricite_tous_sites_ht"
            text-label="Commun pour tous les sites"
            placeholder="Prix en €/kWh"
            class="mb-1"
            type="number"
            suffix="€/kWh"
            @switchChange="entite.prix_moyen_electricite_tous_sites_ht = $event; saveEntite()"
            @blur="saveEntite"
          />
          <div v-if="prix_electricite_par_campus_ok">
            <form-fields-text-and-switch 
              v-for="(site, index) in sites"
              :key="index"
              v-model="site.prix_electricite_campus"
              :switch-value="site.prix_electricite_campus_ht"
              :text-label="site.nom"
              placeholder="Prix en €/kWh"
              class="mb-1"
              type="number"
              @switchChange="site.prix_electricite_campus_ht = $event; updateSite(site)"
              @blur="updateSite(site)"
            />
          </div>
          <form-fields-radio-group 
            v-model="preference_cout_moyen_etp"
            field-label="Préférez-vous rentrer un coût moyen ETP ou différencier les coûts ETP par Grade A+/ A/ B/ C" 
            :radio-data-list="preferenceList"
            class="mb-4"
            @change="saveFormAnswer"
          />
          <form-fields-text
            v-if="preference_cout_moyen_etp == true"
            v-model="cout_moyen_etp"
            field-label="Coût moyen ETP par profil"
            placeholder="Coût moyen ETP"
            field-description="Taux moyen chargé"
            input-suffix="€/jour"
            class="mb-1"
            type="number"
            number-integer
            @blur="saveFormAnswer"
          />
          <v-row 
            v-if="!preference_cout_moyen_etp" 
            class="mt-0"
          >
            <v-col cols="4">
              <form-fields-label
                label="Coût ETP (Équivalent temps plein) par Grade A+"
              />
              <base-commentaire>
                Grade A+ exemple : profil IGR = Ingénieur de recherche. Coût ETP avec charges.
              </base-commentaire>
            </v-col>
            <v-col cols="4">
              <form-fields-text
                v-model="cout_etp_igr"
                placeholder="Coût par Grade A+"
                class="mb-1 mt-2"
                input-suffix="€/jour"
                number-integer
                @blur="saveFormAnswer"
              />
            </v-col>
            <v-col cols="4">
              <form-fields-text 
                v-model="cout_etp_igr_pourcentage"
                class="mb-1 mt-2"
                placeholder="% du total"
                type="number"
                number-integer
                :error-messages="errorMessage"
                input-suffix="%"
                @blur="saveFormAnswer"
              />
            </v-col>
          </v-row>
          <v-row 
            v-if="!preference_cout_moyen_etp"  
            class="mt-0"
          >
            <v-col cols="4">
              <form-fields-label
                label="Coût ETP (Équivalent temps plein) par Grade A"
              />
              <base-commentaire>
                Grade A exemple : profil IGE = Ingénieur d'études. Coût ETP avec charges.
              </base-commentaire>
            </v-col>
            <v-col cols="4">
              <form-fields-text
                v-model="cout_etp_ige"
                placeholder="Coût par Grade A"
                class="mb-1 mt-2"
                input-suffix="€/jour"
                number-integer
                @blur="saveFormAnswer"
              />
            </v-col>
            <v-col cols="4">
              <form-fields-text 
                v-model="cout_etp_ige_pourcentage"
                class="mb-1 mt-2"
                placeholder="% du total"
                type="number"
                number-integer
                :error-messages="errorMessage"
                input-suffix="%"
                @blur="saveFormAnswer"
              />
            </v-col>
          </v-row>
          <v-row 
            v-if="!preference_cout_moyen_etp"
            class="mt-0"
          >
            <v-col cols="4">
              <form-fields-label
                label="Coût ETP (Équivalent temps plein) par Grade B"
              />
              <base-commentaire>
                Grade B exemple : profil TCH = Technicien. Coût ETP avec charges.
              </base-commentaire>
            </v-col>
            <v-col cols="4">
              <form-fields-text
                v-model="cout_etp_tch"
                placeholder="Coût par Grade B"
                class="mb-1 mt-2"
                input-suffix="€/jour"
                number-integer
                @blur="saveFormAnswer"
              />
            </v-col>
            <v-col cols="4">
              <form-fields-text 
                v-model="cout_etp_tch_pourcentage"
                class="mb-1 mt-2"
                placeholder="% du total"
                type="number"
                number-integer
                :error-messages="errorMessage"
                input-suffix="%"
                @blur="saveFormAnswer"
              />
            </v-col>
          </v-row>
          <v-row 
            v-if="!preference_cout_moyen_etp"
            class="mt-0"
          >
            <v-col cols="4">
              <form-fields-label
                label="Coût ETP (Équivalent temps plein) par grade C"
              />
              <base-commentaire>
                Grade C exemple : profil ATRF = Adjoint technique de recherche et de formation. Coût ETP avec charges.
              </base-commentaire>
            </v-col>
            <v-col cols="4">
              <form-fields-text
                v-model="cout_etp_grade_c"
                placeholder="Coût par grade C"
                class="mb-1 mt-2"
                input-suffix="€/jour"
                number-integer
                @blur="saveFormAnswer"
              />
            </v-col>
            <v-col cols="4">
              <form-fields-text 
                v-model="cout_etp_grade_c_pourcentage"
                class="mb-1 mt-2"
                placeholder="% du total"
                type="number"
                number-integer
                :error-messages="errorMessage"
                input-suffix="%"
                @blur="saveFormAnswer(); validatePourcentage()"
              />
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-col cols="4">
              <form-fields-label label=" Temps annuel des interruptions de service des locaux informatiques" />
            </v-col>
            <v-col cols="2">
              <span class="text-h3">Sur 1 an:</span>
              <form-fields-text
                v-model="interruption_service_1_an"
                class="mt-2"
                placeholder="Interruptions"
                field-description="Interruption de service sur la dernière année"
                type="number"
                number-integer
                input-suffix="heures"
                @blur="saveFormAnswer"
              />
            </v-col>
            <v-col cols="3">
              <span class="mr-2 text-body-2">Sur 5 ans:</span>
              <form-fields-text
                v-model="interruption_service_5_ans"
                placeholder="Interruptions"
                field-description="Interruptions de service sur 5 ans"
                class="mt-2"
                type="number"
                number-integer
                input-suffix="heures"
                @blur="saveFormAnswer"
              />
            </v-col>
            <v-col cols="3">
              <span class="mr-2 text-body-2">Sur 10 ans:</span>
              <form-fields-text
                v-model="interruption_service_10_ans"
                placeholder="Interruptions"
                field-description="Interruptions de service sur 10 ans"
                class="mt-2"
                type="number"
                number-integer
                input-suffix="heures"
                @blur="saveFormAnswer"
              />
            </v-col>
          </v-row>
          <form-fields-text 
            v-model="nombre_personne_impactees"
            field-label="Nombre de personnes impactées par les interruptions de service"
            placeholder="Nombre de personnes impactées par les interruptions de service"
            field-description="Les personnes impactées sont toutes les personnes administratives, des laboratoires, des services métiers etc."
            type="number"
            number-integer
            class="mb-6"
            :error-messages="errorMessageRatioImpact"
            @blur="saveFormAnswer(); validateRatioImpact()"
          />
          <form-fields-slider
            v-model="cout_interruption_service"
            field-label="Coût d'une interruption de service par jour (indisponibilité d'un datacenter)"
            min="0"
            max="500000"
            hint="Coût d'une interruption de service par jour = Nombre de personnes impactées par les interruptions de service X taux journalier moyen."
            persistent-hint
            class="mb-6 mt-8"
            readonly
          />
          <form-fields-textarea
            v-model="commentaire_ratios_interne"
            field-label="Commentaires" 
            placeholder="Commentaires"
            type="textarea"
            class="mt-5"
            @blur="saveFormAnswer"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormStepTitle from '@/components/FormStepTitle.vue'
import FormFieldsRadioGroup from '@/components/FormFieldsRadioGroup.vue'
import FormFieldsText from '@/components/FormFieldsText.vue'
import FormFieldsSlider from '@/components/FormFieldsSlider.vue'
import FormFieldsTextarea from '@/components/FormFieldsTextarea.vue'
import FormFieldsTextAndSwitch from '@/components/FormFieldsTextAndSwitch.vue'
import { mapActions, mapMutations, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import FormFieldsLabel from '@/components/FormFieldsLabel.vue'
import BaseCommentaire from '@/components/BaseCommentaire.vue'

export default {
  name: "InternRatios",
  components: { 
    FormStepTitle,
    FormFieldsRadioGroup,
    FormFieldsText,
    FormFieldsSlider,
    FormFieldsTextarea,
    FormFieldsTextAndSwitch,
    FormFieldsLabel,
    BaseCommentaire
  },
  data () {
    return {
      preferenceList: [
        {
          label: "Coût moyen ETP",
          value: true,
        },
        {
          label: "Coût ETP par Grade A+ / A / B / C",
          value: false,
        }
      ],
      preferenceElecList: [
        {
          label: 'Coût par site',
          value: true,
        },
        {
          label: 'Coût moyen pour tous les sites',
          value: false,
        },
      ],
      errorMessage:"",
      errorMessageRatioImpact: "",
    }
  },
  computed: {
    ...mapState('form', ['sites', 'entite']),
    ...mapFields('form', [
      'formAnswer.preference_cout_moyen_etp',
      'formAnswer.cout_moyen_etp',
      'formAnswer.cout_etp_igr',
      'formAnswer.cout_etp_ige',
      'formAnswer.cout_etp_tch',
      'formAnswer.cout_interruption_service',
      'formAnswer.interruption_service_1_an',
      'formAnswer.interruption_service_5_ans',
      'formAnswer.interruption_service_10_ans',
      'formAnswer.commentaire_ratios_interne',
      'formAnswer.personnel_entite',
      'formAnswer.cout_etp_grade_c',
      'formAnswer.nombre_personne_impactees',
      'formAnswer.cout_etp_igr_pourcentage',
      'formAnswer.cout_etp_ige_pourcentage',
      'formAnswer.cout_etp_tch_pourcentage',
      'formAnswer.cout_etp_grade_c_pourcentage',
      'formAnswer.prix_electricite_par_campus_ok',
      'formAnswer.nombre_heures_interruption_derniere_annee',
      'formAnswer.nombre_heures_interruption_trois_derniere_annee',
      'formAnswer.cout_moyen_etp_jours_calendaires',
    ]),
  },
  created() {
    this.fetchFormAnswer();
    this.fetchSites();
    this.fetchEntite();
    this.setNextView('/ressources/current-host');
  },
  methods: {
    ...mapActions('form', ['fetchFormAnswer', 'saveFormAnswer', 'fetchSites', 'fetchEntite', 'saveEntite', 'updateSite']),
    ...mapMutations('stepper', ['setNextView']),
    validatePourcentage() {
      const value = Number(this.cout_etp_igr_pourcentage) + Number(this.cout_etp_ige_pourcentage) + Number(this.cout_etp_tch_pourcentage) + Number(this.cout_etp_grade_c_pourcentage)
      if (value !== 100) {
        this.errorMessage = "La somme des 4 champs doit être égale à 100%"
      } 
    },
    validateRatioImpact() {
      const value = (this.nombre_personne_impactees / this.personnel_entite) * 100
      if(value < 5) {
        this.errorMessageRatioImpact = "Cette valeur parait faible au regard du nombre de personnes de votre Etablissement"
      }
    }
  }
}
</script>