<template>
  <base-complex-field
    :placeholder="placeholder"
    :label="textLabel"
    :number-integer="numberInteger"
    :number-decimal="numberDecimal"
    :hint="textFieldHint"
    :suffix="suffix"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <base-switch
      :input-value="switchValue"
      :label="switchLabel" 
      :false-value="falseValue"
      :true-value="trueValue"
      :prepend-label="switchPrependLabel"
      @change="$emit('switchChange', $event)"
    />
  </base-complex-field>
</template>

<script>
import BaseSwitch from '@/components/BaseSwitch.vue'
import BaseComplexField from '@/components/BaseComplexField.vue'

export default {
  name: "FormFieldsTextAndSwitch",
  components: {
    BaseComplexField,
    BaseSwitch
  },
  props: {
    textLabel: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    textFieldHint: {
      type: String,
      default: ""
    },
    switchLabel: {
      type: String,
      default: "TTC"
    },
    falseValue: {
      type: [Boolean, String],
      default: true
    },
    trueValue: {
      type: [Boolean, String],
      default: false,
    },
    switchPrependLabel: {
      type: String,
      default: "HT"
    },
    switchValue: {
      type: [Boolean, String],
      default: false,
    },
    numberInteger: {
      type: Boolean,
      default: false,
    },
    numberDecimal: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: null,
    },
  },
}
</script>