<template>
  <base-form-field :label="fieldLabel">
    <v-slider
      v-bind="$attrs"
      color="cassiopee-purple"
      track-color="cassiopee-purple lighten-1"
      thumb-color="cassiopee-purple"
      thumb-size="47"
      thumb-label="always"
      height="56"
      class="form-slider text-subtitle-2"
      :class="{'form-slider__littleSize': littleSize}"
      v-on="$listeners"
    >
      <template #thumb-label="{ value }">
        <span v-if="currency">
          {{ value | toCurrency }}
        </span>
        <span v-else>
          {{ value }}
        </span>
      </template>
    </v-slider>
  </base-form-field>
</template>

<script>
import BaseFormField from './BaseFormField.vue'
import filters from "@/mixins/filters.js"

export default {
  name: "FormFieldsSlider",
  components: { BaseFormField },
  mixins: [filters],
  props: {
    littleSize: {
      type: Boolean,
      default: false,
    },
    fieldLabel: {
      type: String,
      default: ""
    },
    currency: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';

.form-slider {

  &__littleSize{
    max-width: 383px !important;
  }
}

.v-slider__thumb-label {
  border-radius: 0px !important;
  transform: translateY(-20%) translateY(-12px) translateX(-50%) !important;
  height: 35px !important;
  width: 70px !important;

  & div > span {
    transform: rotate(45deg) !important;
    -ms-transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
    display: inline-block;
    font-size: 0.8125rem;
    line-height: 1.85em;
  }
}

.v-slider--horizontal .v-slider__track-container {
  height: 5px !important;
}
</style>