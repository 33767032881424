<template>
  <div>
    <v-radio
      v-if="isRadioOnly"
      v-bind="$attrs"
      color="cassiopee-purple"
      class="mr-9 text-2"
      v-on="$listeners"
    >
      <template #label>
        <span class="cassiopee-grey--text text--darken-1 text-subtitle-2"> {{ label }}</span>
      </template>
    </v-radio>
    
    <v-radio
      v-else
      v-bind="$attrs"
      color="cassiopee-purple"
      class="form-radio-button pa-7 pl-9 mr-1"
      active-class="active-radio-button"
      v-on="$listeners"
    >
      <template #label>
        <span class="cassiopee-grey--text text--darken-1 text-subtitle-2"> {{ label }}</span>
      </template>
    </v-radio>
  </div>
</template>

<script>
export default {
  name: "BaseFormRadioButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    isRadioOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.form-radio-button {
  color: map-deep-get($colors, 'cassiopee-grey', 'darken-1');
  max-height: 50px;
  border: 1px solid map-deep-get($colors, 'cassiopee-grey', 'darken-1');
  border-radius: 8px;
}

.active-radio-button {
  background: map-deep-get($colors, 'cassiopee-purple', 'lighten-1') !important;
  color: map-deep-get($colors, 'cassiopee-purple', 'base') !important;
  border: 1px solid map-deep-get($colors, 'cassiopee-purple', 'base') !important;

  > .v-label {
    > span {
      color: map-deep-get($colors, 'cassiopee-purple', 'base') !important;
    }
  }
}
</style>