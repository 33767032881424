<template>
  <v-row>
    <v-col 
      v-if="label"
      cols="4"
      class="d-flex py-0 flex-column"
    >
      <form-fields-label :label="label" />
      <base-commentaire>
        {{ commentaire }}
      </base-commentaire>
    </v-col>
    <v-col 
      :cols="label ? 6 : 10"
      class="py-0"
      :class="{ 'd-flex align-center': centered }"
    >
      <v-text-field
        :placeholder="placeholder"
        type="number"
        class="text-subtitle-2"
        :class="{'form-input__alignRight': suffix}"
        color="cassiopee-purple"
        height="50"
        outlined
        :rules="selectedRules"
        :success="validateValue"
        :suffix="suffix"
        v-bind="$attrs"
        :hint="hint"
        :persistent-hint="!!hint"
        v-on="$listeners"
        @change="$emit('input', $event)"
      />
    </v-col>
    <v-col 
      cols="2"
      class="py-0"
      :class="{ 'd-flex align-center': centered }"
    >
      <slot />
    </v-col>
  </v-row>
</template>

<script>
import validateField from "@/mixins/validateField.js"
import FormFieldsLabel from "@/components/FormFieldsLabel.vue"
import BaseCommentaire from "@/components/BaseCommentaire.vue"

export default {
  name: 'BaseComplexField',
  components: {
    FormFieldsLabel,
    BaseCommentaire
  },
  mixins: [validateField],
  props: {
    hint: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: ""
    },
    numberInteger: {
      type: Boolean,
      default: false,
    },
    numberDecimal: {
      type: Boolean,
      default: false,
    },
    commentaire: {
      type: String,
      default: ""
    },
     centered: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss">

.form-input {
  &__alignRight input {
    text-align: right !important;
  }
}
</style>