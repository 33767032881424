<template>
  <div class="d-flex align-center">
    <span class="pr-4 cassiopee-grey--text text--darken-2 pb-4 text-subtitle-2">
      {{ prependLabel }}
    </span>
    <v-switch
      v-bind="$attrs"
      color="cassiopee-purple"
      inset
      v-on="$listeners"
    >
      <template #label>
        <span class="cassiopee-grey--text text--darken-2 text-subtitle-2"> {{ label }}</span>
      </template>
    </v-switch>
  </div>
</template>

<script>
export default {
  name: "BaseSwitch",
  props: {
    label: {
      type: String,
      required: true,
    },
    prependLabel: {
      type: String,
      default: ""
    },
  },
}
</script>

<style scoped>

.v-input--switch__thumb {
  color: white!important;
}

</style>