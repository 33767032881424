<template>
  <base-form-field
    :label="fieldLabel"
  >
    <v-textarea
      v-bind="$attrs"
      :placeholder="placeholder"
      class="form-input text-subtitle-2"
      color="cassiopee-purple"
      row-height="30"
      outlined
      :rules="selectedRules"
      :success="validateValue"
      v-on="$listeners"
    />
  </base-form-field>
</template>

<script>
import BaseFormField from './BaseFormField.vue'
import validateField from "@/mixins/validateField.js"
export default {
  name: "FormFieldsTextarea",
  components: { BaseFormField },
  mixins: [validateField],
  props: {  
    placeholder: {
      type: String,
      default: ""
    },
    fieldLabel: {
      type: String,
      default: "",
    }
  }
}
</script>