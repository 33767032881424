<template>
  <base-form-field 
    :label="fieldLabel"
    :required="required"
  >
    <v-radio-group 
      row
      v-bind="$attrs"
      v-on="$listeners"
      @change="$emit('input', $event)"
    >
      <base-form-radio-button 
        v-for="(radio, i) in radioDataList" 
        :key="i"
        :label="radio.label || radio" 
        :value="radio.value == undefined ? radio : radio.value"
        :is-radio-only="simpleRadioButton"
        :class="{'base-form-radio-button' : !simpleRadioButton}"
      />
    </v-radio-group>
  </base-form-field>
</template>

<script>
import BaseFormField from "./BaseFormField"
import BaseFormRadioButton from "./BaseFormRadioButton"

export default {
  name:"FormFieldsRadioGroup",
  components: { 
    BaseFormField,
    BaseFormRadioButton
  },
  props: {
    radioDataList: {
      type: Array,
      required: true
    },
    fieldLabel: {
      type: String,
      default: ""
    },
    simpleRadioButton: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss">
.v-input--selection-controls {
  margin-top: 0;
}
.v-input--selection-controls .v-btn__content,
.v-input--selection-controls button .v-input__slot > .v-label,
.v-input--selection-controls button .v-radio > .v-label {
  position: absolute !important;
  left:0 !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  display: flex !important;
}

.v-input--selection-controls .v-btn__content {
  padding-left:50px;
  justify-content: flex-start;
}
.v-input--selection-controls button .v-input__slot > .v-label,
.v-input--selection-controls button .v-radio > .v-label {
  padding-left:90px;
}

.v-input--radio-group--row .v-input--radio-group__input {
  flex-wrap: nowrap !important;
 
}
</style>

<style lang="scss" scoped>
.base-form-radio-button {
  width: 100%;
}
</style>