<template>
  <div>
    <div class="d-flex align-end">
      <v-icon
        v-if="icon"
        size="48"
        class="mr-5"
      >
        {{ icon }}
      </v-icon>
      <div class="flex-1">
        <h1 class="text-h1 cassiopee-purple--text mb-3 pl-3">
          {{ title }}
        </h1>
        <v-divider class="cassiopee-purple" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormStepTitle",
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: undefined,
    },
  },
};
</script>